export const socialIconList = [
  {
    name: 'github',
    prefix: 'fab',
    link: 'https://github.com/caroaguilar',
  },
  {
    name: 'linkedin',
    prefix: 'fab',
    link: 'https://linkedin.com/in/caroagse',
  },
  {
    name: 'medium',
    prefix: 'fab',
    link: 'https://medium.com/@caroagse',
  },
  {
    name: 'instagram',
    prefix: 'fab',
    link: 'https://www.instagram.com/caroagse'
  }
];
